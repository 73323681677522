import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
// import style css
import "./ReadMoreNews.scss";
// import left and right icon
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import looptech from "../../Helpers/looptech";
// import swiper datas
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Mousewheel, Autoplay } from "swiper";
// import  Fancybox
import Fancybox from "../../Components/Fancybox";
import { useContext } from "react";
import { TrContext } from "../../TrUseContext/TrUseContext";

function ReadMoreNews() {
    const { lang } = useContext(TrContext);
    const { slug } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [news, setNews] = useState({});

    const getNewsData = async () => {
        try {
            const oneNewsData = await looptech.api().get(`news/${slug}?lang=${lang}`);
            setNews(oneNewsData.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getNewsData();
    }, [lang]);
    // console.log("news in data" , news);
    return (
        <>
            <section id="read-more-news">
                <div className="container">
                    <div className="tr-one-news">
                        <Fancybox>
                            <Swiper
                                direction={"horizontal"}
                                slidesPerView={1}
                                loop={true}
                                mousewheel={true}
                                modules={[Mousewheel, Autoplay]}
                                className="mySwiper"
                                breakpoints={{
                                    1024: {
                                        direction: "vertical",
                                        slidesPerView: 2.1,
                                        spaceBetween: 20,
                                        centeredSlides: true,
                                        modules: "Mousewheel",
                                    },
                                }}
                            >
                                <SwiperSlide>{news.data?.cover_image && <img data-fancybox="gallery" loading="lazy" src={`${looptech.baseUrlImage}${news.data?.cover_image}`} alt="burada xebere aid sekil var" />}</SwiperSlide>
                                {news.data?.gallery?.map((image, index) => (
                                    <SwiperSlide className="cover" key={index}>
                                        {image.endsWith(".mp4") ? <video data-fancybox="gallery" src={`${looptech.baseUrlImage}${image}`} loading="lazy" muted autoPlay></video> : <img data-fancybox="gallery" src={`${looptech.baseUrlImage}${image}`} loading="lazy" alt="burada xebere aid sekil var" />}
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Fancybox>

                        <div className="read-news">
                            <h4>{news.data?.title}</h4>
                            {news.data && <span>{news.data?.created_at}</span> / <span>{news.data?.views}</span>}  
                            <p dangerouslySetInnerHTML={{ __html: news.data?.body }}></p>
                        </div>
                    </div>
                    {news.previous ? (
                        <Link to={`/news/${news.previous}`} className="left">
                            <BiChevronLeft className="icon" />
                        </Link>
                           ) 
                    : 
                    (
                        ""
                    )}
                    {news.next ? (
                        <Link to={`/news/${news.next}`} className="right">
                            <BiChevronRight className="icon" />
                        </Link>
                    ) : (
                        ""
                    )}
                </div>
            </section>
        </>
    );
}

export default ReadMoreNews;
