import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import style css
import "./MultimediaSeeMore.scss";
// import ScrollReveal
import ScrollReveal from "scrollreveal";
// import  Fancybox
import Fancybox from "../../Components/Fancybox";
import looptech from "../../Helpers/looptech";
import { useContext } from "react";
import { TrContext } from "../../TrUseContext/TrUseContext";
import LoadingComponent from "../LoadingComp/LoadingComponent";
// import image
import play from "../../assets/images/play.png";
     
function MultimediaSeeMore() {
    const { lang } = useContext(TrContext);
    const { slug } = useParams();

    const [seeMoreMultimedia, setSeeMoreMultimedia] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const videoImageHandler = (video) => {
        var regex = /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user|shorts)\/))([^\?&"'>]+)/;
        var matches = video.match(regex);
        if (matches && matches.length > 1) {
            return matches[1];
        } else {
            return null; // No match found
        }
    };

    const seeMultimediaGetDatas = async () => {
        try {
            const seeMultiRes = await looptech.api().get(`multimedia/${slug}?lang=${lang}`);
            setSeeMoreMultimedia(seeMultiRes.data.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        seeMultimediaGetDatas();
    }, [lang]);

    //    console.log("multi in data ", seeMoreMultimedia);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        if (seeMoreMultimedia) {
            ScrollReveal().reveal(".mult-image", {
                interval: 600,
            });
        }
    }, [seeMoreMultimedia]);
    return (
        <>
            {loading ? (
                <LoadingComponent />
            ) : (
                <section id="multimedia-see-more">
                    <h4>{seeMoreMultimedia.title}</h4>
                    <div className="container">
                        <Fancybox>
                            <div className="see-more-images">
                                {seeMoreMultimedia.cover_image && <img src={`${looptech.baseUrlImage}${seeMoreMultimedia.cover_image}`} data-fancybox="gallery" alt="burada multimedaidan gelen sekli var" loading="lazy" />}
                                {seeMoreMultimedia.images?.map((image, index) => (
                                    <img className="mult-image" key={index} src={`${looptech.baseUrlImage}${image}`} data-fancybox="gallery" alt="burada multimedaidan gelen sekliller var " loading="lazy" />
                                ))}

                                {seeMoreMultimedia.videos?.map(
                                    (video, index) =>
                                        videoImageHandler(video) && (
                                            <a href={`https://www.youtube-nocookie.com/watch?v=${videoImageHandler(video)}`} 
                                            key={index} data-fancybox="gallery">
                                                <img src={`https://img.youtube.com/vi/${videoImageHandler(video)}/mqdefault.jpg`} alt="" />
                                                <img className="playButton" src={play} alt="" />
                                            </a>
                                        )
                                )}
                            </div>
                        </Fancybox>
                    </div>
                </section>
            )}
        </>
    );
}

export default MultimediaSeeMore;
