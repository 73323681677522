import { Route, Routes } from "react-router-dom";
import HowToStart from "./Components/HowToStart/HowToStart";
import History from "./Components/TriathlonHistory/History";
import Events from "./Pages/Events/Events";
import Athletes from "./Pages/Athletes/Athletes";
import Results from "./Pages/Results/Results";
import Federation from "./Pages/Federation/Federation";
import Contact from "./Pages/Contact/Contact";
import News from "./Pages/News/News";
import ReadMoreNews from "./Components/ReadMoreNews/ReadMoreNews";
import Multimedia from "./Pages/Multimedia/Multimedia";
import MultimediaSeeMore from "./Components/MultimediaSeeMore/MultimediaSeeMore";
import HomePage from "./Pages/Home/Home";
import MainLayout from "./layouts/MainLayout";
import Registration from "./Components/Registration/Registration";
import AthletesDetails from "./Components/AthletesDetails/AthletesDetails";

function App() {
    return (
        <Routes>
            <Route path=":locale?/" element={<MainLayout />}>   
                <Route index element={<HomePage />} />
                <Route path="events" element={<Events />} />
                <Route path="results" element={<Results />} />
                <Route path="athletes" element={<Athletes />} />
                <Route path="multimedia" element={<Multimedia />} />
                <Route path="federation" element={<Federation />} />
                <Route path="contact" element={<Contact />} />
                <Route path="news" element={<News />} />

                <Route path="how-to-start" element={<HowToStart />} />
                <Route path="history" element={<History />} />
                <Route path="registration" element={<Registration />} />
                <Route path="athletes/:slug" element={<AthletesDetails />} />
                <Route path="news/:slug?" element={<ReadMoreNews />} />
                <Route path="multimedia/:slug?" element={<MultimediaSeeMore />} />
            </Route>
        </Routes>
    );
}

export default App;
