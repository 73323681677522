import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const mapLocation = require("../../assets/images/Azerbaijan.png");
const icon = L.icon({
    iconUrl: mapLocation,
    iconSize: [58, 58],
});

const position = [40.42853, 49.89178];

function Map() {
    return (
        <>
            <MapContainer center={position} zoom={12} scrollWheelZoom={true} style={{ width: "100%", height: "100%", zIndex: "5" }}>
                <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={position} icon={icon}>
                    <Popup></Popup>
                </Marker>
            </MapContainer>
        </>
    );
}

export default Map;
